export function generateDocDefinition(data: { bomItem: any; user: any }) {
  const { bomItem, user } = data;

  const size =
    bomItem.material.type === "Fabric"
      ? bomItem.material.fabric?.fabricSize?.name
      : bomItem.material.trim?.trimSize?.name;

  const color =
    bomItem.material.type === "Fabric"
      ? bomItem.material.fabric?.fabricColor?.name
      : bomItem.material.trim?.trimColor?.name;

  const materialType = bomItem.material.type === "Fabric" ? "Fabric" : "Trim";
  const materialName =
    materialType === "Fabric"
      ? bomItem.material.fabric?.name
      : bomItem.material.trim?.name;

  const materialDetails: any =
    materialType === "Fabric" ? bomItem.material.fabric : bomItem.material.trim;

  return {
    content: [
      // Header Section
      {
        text: "Purchase Order",
        style: "header",
        margin: [0, 0, 0, 10],
      },
      {
        columns: [
          {
            text: `Order Date: ${bomItem?.inHouseDate || "N/A"}`,
            style: "subheader",
          },
          {
            text: `PO Number: PO-BO/${bomItem?.id || "N/A"}`,
            style: "subheader",
            alignment: "right",
          },
        ],
        margin: [0, 0, 0, 20],
      },

      // Buyer Details
      {
        table: {
          headerRows: 0,
          widths: ["*"],
          body: [
            [
              {
                stack: [
                  { text: "Supplier Information", style: "sectionHeader" },
                  `Name: ${bomItem?.supplier?.name || "N/A"}`,
                  `Address: ${bomItem?.supplier?.addressLine1 || ""} ${
                    bomItem?.supplier?.addressLine2 || ""
                  }`,
                  `City: ${bomItem?.supplier?.city || "N/A"}`,
                  `State: ${bomItem?.supplier?.state || "N/A"}`,
                  `Country: ${bomItem?.supplier?.country || "N/A"}`,
                  `Pin Code: ${bomItem?.supplier?.pinCode || "N/A"}`,
                  bomItem?.supplier?.contactPerson
                    ? `Contact Person: ${bomItem?.supplier?.contactPerson}`
                    : "",
                  bomItem?.supplier?.contactNo
                    ? `Contact No: ${bomItem?.supplier?.contactNo}`
                    : "",
                  bomItem?.supplier?.email
                    ? `Email: ${bomItem?.supplier?.email}`
                    : "",
                ],
                margin: [10, 10, 10, 10],
                fillColor: "#f9f9f9",
              },
            ],
          ],
        },
        layout: "noBorders",
      },

      // Supplier Details
      {
        table: {
          headerRows: 0,
          widths: ["*"],
          body: [
            [
              {
                stack: [
                  { text: "Buyer Information", style: "sectionHeader" },
                  `Name: ${user?.factory[0]?.factoryName || "N/A"}`,
                  `Contact Person: ${user?.name || "N/A"}`,
                  `Number: ${user?.mobileNo || "N/A"}`,
                  `Email: ${user?.email || "N/A"}`,
                ],
                margin: [10, 10, 10, 10],
                fillColor: "#f9f9f9",
              },
            ],
          ],
        },
        layout: "noBorders",
        margin: [0, 10, 0, 10],
      },

      // Material Details
      {
        text: "Material Details",
        style: "sectionHeader",
        margin: [0, 10, 0, 5],
      },
      {
        table: {
          headerRows: 1,
          widths: ["*", "*", "*", "*", "*", "*"],
          body: [
            [
              { text: "Material Name", style: "tableHeader" },
              { text: "Type", style: "tableHeader" },
              { text: "Size", style: "tableHeader" },
              { text: "Color", style: "tableHeader" },
              { text: "Description", style: "tableHeader" },
              { text: "Composition", style: "tableHeader" },
            ],
            [
              { text: materialName || "N/A", style: "tableData" },
              { text: materialType || "N/A", style: "tableData" },
              { text: size || "N/A", style: "tableData" },
              { text: color || "N/A", style: "tableData" },
              {
                text: materialDetails?.description || "N/A",
                style: "tableData",
              },
              {
                text: materialDetails?.composition || "N/A",
                style: "tableData",
              },
            ],
          ],
        },
        layout: {
          fillColor: (rowIndex: any) => (rowIndex === 0 ? "#007bff" : null),
        },
        margin: [0, 10, 0, 10],
      },

      // Order Summary
      {
        text: "Order Summary",
        style: "sectionHeader",
        margin: [0, 10, 0, 5],
      },
      {
        table: {
          headerRows: 1,
          widths: ["*", "*", "*", "*", "*"],
          body: [
            [
              { text: "Quantity", style: "tableHeader" },
              { text: "Unit", style: "tableHeader" },
              { text: `Extra Quantity (%)`, style: "tableHeader" },
              {
                text: `Per Piece Rate (${bomItem?.currency?.symbol || ""})`,
                style: "tableHeader",
              },
              {
                text: `Total Rate (${bomItem?.currency?.symbol || ""})`,
                style: "tableHeader",
              },
            ],
            [
              { text: bomItem?.quantity || "N/A", style: "tableData" },
              { text: bomItem?.unit?.name || "N/A", style: "tableData" },
              { text: `${bomItem?.extra || 0}%`, style: "tableData" },
              {
                text: bomItem?.rate?.toFixed(2) || "N/A",
                style: "tableData",
              },
              {
                text: bomItem?.total?.toFixed(2) || "N/A",
                style: "tableData",
              },
            ],
            [
              { text: "", colSpan: 3, border: [false, false, false, false] },
              {},
              {},
              { text: "Total Quantity:", style: "totalsLabel" },
              {
                text: `${
                  bomItem?.quantity +
                    (bomItem?.extra * bomItem?.quantity) / 100 || 0
                } ${bomItem?.unit?.name || ""}`,
                style: "totalsValue",
              },
            ],
            [
              { text: "", colSpan: 3, border: [false, false, false, false] },
              {},
              {},
              { text: "Total Cost:", style: "totalsLabel" },
              {
                text: `${
                  bomItem?.currency?.symbol || ""
                } ${bomItem?.total?.toFixed(2)}`,
                style: "totalsValue",
              },
            ],
          ],
        },
        layout: {
          fillColor: (rowIndex: any) => (rowIndex === 0 ? "#007bff" : null),
        },
        margin: [0, 10, 0, 10],
      },

      // Footer
      {
        text: "Thank you for your business!",
        style: "footer",
        margin: [0, 20, 0, 0],
      },
    ],
    styles: {
      header: {
        fontSize: 22,
        bold: true,
        alignment: "center",
      },
      subheader: {
        fontSize: 12,
        bold: true,
      },
      sectionHeader: {
        fontSize: 14,
        bold: true,
        margin: [0, 10, 0, 5],
      },
      tableHeader: {
        bold: true,
        fontSize: 12,
        color: "white",
        fillColor: "#007bff",
        alignment: "center",
      },
      tableData: {
        fontSize: 10,
        alignment: "center",
      },
      totalsLabel: {
        bold: true,
        alignment: "right",
      },
      totalsValue: {
        bold: true,
        color: "#007bff",
        alignment: "center",
      },
      footer: {
        fontSize: 10,
        alignment: "center",
        color: "#777",
      },
    },
    defaultStyle: {
      font: "Roboto",
    },
  };
}
