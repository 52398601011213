import React, { useState, useEffect } from "react";
import { ITNAOrder } from "../../../types/groyyo_plus/tna";
import { Button, Progress, Modal, Input, Radio, message } from "antd";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import palette from "../../../constants/pallete";
import { SearchOutlined } from "@ant-design/icons";
import { getTnaTemplates } from "../../../services/orderServiceApi/tnaApi";
import { useDispatch } from "react-redux";
import { setSelectedTemplate } from "../../../redux/tna/tnaTemplateSlice";
import { useTranslation } from "react-i18next";

interface ITNACardProps {
  tna: ITNAOrder;
}

interface TnaTemplate {
  id: number;
  name: string;
  factoryId: number;
}

const TNACard = ({ tna }: ITNACardProps) => {
  const { t }: any = useTranslation(); 
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [templates, setTemplates] = useState<TnaTemplate[]>([]);
  const [filteredTemplates, setFilteredTemplates] = useState<TnaTemplate[]>([]);
  const [selectedTemplateId, setSelectedTemplateId] = useState<number | null>(null);
  const [searchText, setSearchText] = useState("");
  const [loadingTemplates, setLoadingTemplates] = useState(false);

  const completedSteps = tna?.tna?.completedSteps;
  const totalSteps = tna?.tna?.noOfSteps;
  const progressPercentage = (completedSteps / totalSteps) * 100;
  const isTNA = tna?.tna;

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const navigatetoTnaDetails = (salesOrderItemId: number) => {
    navigate(`/tna/details/${salesOrderItemId}`);
  };

  const handleModalOpen = async () => {
    setIsModalVisible(true);
    await fetchTemplates();
  };

  const handleModalClose = () => {
    setIsModalVisible(false);
    setSelectedTemplateId(null);
    setSearchText("");
  };

  const fetchTemplates = async () => {
    setLoadingTemplates(true);
    try {
      const response = await getTnaTemplates();
      const responseData = response?.data;

      if (responseData) {
        setTemplates(responseData?.result || []);
        setFilteredTemplates(responseData?.result || []);
      } else {
        message.error(t("tnaPage.tnaCard.loadingTemplates"));
      }
    } catch (error) {
      console.error("Error fetching templates:", error);
      message.error(t("tnaPage.tnaCard.loadingTemplates"));
    } finally {
      setLoadingTemplates(false);
    }
  };

  const handleTemplateSelect = (templateId: number) => setSelectedTemplateId(templateId);

  const handleSearch = (text: string) => {
    setSearchText(text);
    setFilteredTemplates(
      templates.filter((template) =>
        template.name.toLowerCase().includes(text.toLowerCase())
      )
    );
  };

  const handleOnTemplateSelection = (template: any) => {
    dispatch(setSelectedTemplate(template));
    navigate(`/tna/create/${tna?.item?.id}/${template?.id}`);
  };

  const handleCustomTnaTemplate = () => {
    dispatch(setSelectedTemplate(null));
    navigate(`/tna/create/${tna?.item?.id}/custom`);
    handleModalClose();
  };

  const handleCreateNewTnaTemplate = () => {
    dispatch(setSelectedTemplate(null));
    navigate(`/tna/create-template`);
    handleModalClose();
  };

  return (
    <>
      <div
        className="p-4 bg-white rounded-lg shadow-md flex flex-col gap-3 cursor-pointer"
        onClick={() => navigatetoTnaDetails(tna?.id)}
      >
        <div className="flex flex-col gap-2">
          <div className="flex flex-row justify-between items-start">
            <div className="flex flex-row gap-3 items-start">
              <img
                src={"/svg/OrderDress.svg"}
                alt={"icon"}
                className="!w-[3.5rem] !h-[3.5rem] rounded-md"
              />
              <div className="flex flex-col">
                <span className="text-lg font-bold">{tna?.salesOrder?.buyer?.name}</span>
                <span className="text-lg font-semibold">{tna?.item?.name}</span>
              </div>
            </div>
            <div className="text-gray-800">
              <div className="flex justify-between text-sm">
                <span className="font-semibold">{t("tnaPage.tnaCard.soDate")}: </span>
                <span>{moment(tna?.salesOrder?.soDate).format("DD-MM-YYYY")}</span>
              </div>
              <div className="flex justify-between text-sm">
                <span className="font-semibold">{t("tnaPage.tnaCard.expectedDeliveryDate")}: </span>
                <span>{moment(tna?.salesOrder?.expectedDate).format("DD-MM-YYYY")}</span>
              </div>
            </div>
          </div>
          <span className="text-base font-bold text-gray-700">{tna?.salesOrder.soNo}</span>
        </div>

        {isTNA ? (
          <div className="mt-4">
            <p className="text-center font-semibold text-gray-700">
              {t("tnaPage.tnaCard.processes")} {completedSteps} / {totalSteps}
            </p>
            <Progress
              percent={progressPercentage}
              showInfo={false}
              strokeColor="green"
              trailColor="#d9d9d9"
            />
          </div>
        ) : (
          <span
            onClick={(e) => {
              e.stopPropagation();
              handleModalOpen();
            }}
            className="text-center text-lg cursor-pointer"
            style={{ color: palette.error }}
          >
            {t("tnaPage.tnaCard.createTNA")}
          </span>
        )}
      </div>

      <Modal
        title={t("tnaPage.tnaCard.selectTemplate")}
        open={isModalVisible}
        onCancel={handleModalClose}
        footer={null}
        centered
        bodyStyle={{
          padding: "20px",
        }}
      >
        <div className="mb-4">
          <label className="block font-semibold mb-2">{t("tnaPage.tnaCard.searchTemplate")}</label>
          <Input
            placeholder={t("tnaPage.tnaCard.searchTemplate")}
            prefix={<SearchOutlined />}
            value={searchText}
            onChange={(e) => handleSearch(e.target.value)}
          />
        </div>
        <div className="mb-4">
          {loadingTemplates ? (
            <p>{t("tnaPage.tnaCard.loadingTemplates")}</p>
          ) : (
            <Radio.Group
              onChange={(e) => handleTemplateSelect(e.target.value)}
              value={selectedTemplateId}
              className="flex flex-col gap-3"
            >
              {filteredTemplates.map((template) => (
                <Radio
                  key={template.id}
                  value={template.id}
                  onClick={() => handleOnTemplateSelection(template)}
                >
                  {template.name}
                </Radio>
              ))}
            </Radio.Group>
          )}
        </div>
        <div className="flex gap-4">
          <Button
            className="w-full"
            style={{
              backgroundColor: palette.secondary,
              color: "white",
              borderRadius: "20px",
            }}
            onClick={handleCustomTnaTemplate}
          >
            {t("tnaPage.tnaCard.custom")}
          </Button>
          <Button
            type="primary"
            className="w-full"
            style={{
              backgroundColor: palette.secondary,
              borderColor: palette.secondary,
              color: "white",
              borderRadius: "20px",
            }}
            onClick={handleCreateNewTnaTemplate}
          >
            {t("tnaPage.tnaCard.add")}
          </Button>
        </div>
      </Modal>
    </>
  );
};

export default TNACard;